<template>
  <div>
    <b-row>
      <b-col>
        <h2>Case {{ actionObj.CaseFileNumber }}</h2>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col>
        <router-link
          :to="'/cases/' + actionObj.CaseID + '/detail'"
        >
          <h3 style="text-decoration: underline">
            Case {{ actionObj.CaseFileNumber }}
          </h3>
        </router-link>
      </b-col>
      <b-col>
        <h3>Case State: {{ actionObj.CaseStateCode }}</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h2>{{ ActivityID ? "Edit Activity" : "Add Activity" }}</h2>
      </b-col>
    </b-row>
    <div class="white-bg-container">
      <b-row>
        <b-col :md="showEstimatedMileage() ? 9 : 5">
          <b-row class="to-border-box">
            <b-col>
              <b-form-group
                label="Activity Begin Time"
                label-for="activityBeginDate"
              >
                <b-row>
                  <b-col>
                    <b-form-input
                      id="activityBeginDate"
                      v-model="ActivityBeginDate"
                      type="date"
                      placeholder=""
                      name="activityBeginDate"
                      :readonly="readOnly"
                    />
                  </b-col>
                  <b-col>
                    <b-form-input
                      id="activityBeginTime"
                      v-model="ActivityBeginTime"
                      type="time"
                      placeholder=""
                      name="activityBeginTime"
                      :readonly="readOnly"
                    />
                  </b-col>
                </b-row>
              </b-form-group>

              <b-form-group
                label="On Site Arrival Time"
                label-for="onSiteArrivalDate"
              >
                <b-row>
                  <b-col>
                    <b-form-input
                      id="onSiteArrivalDate"
                      v-model="OnSiteArrivalDate"
                      type="date"
                      placeholder=""
                      name="onSiteArrivalDate"
                      :readonly="readOnly"
                    />
                  </b-col>
                  <b-col>
                    <b-form-input
                      id="onSiteArrivalTime"
                      v-model="OnSiteArrivalTime"
                      type="time"
                      placeholder=""
                      name="onSiteArrivalTime"
                      :readonly="readOnly"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col v-if="showEstimatedMileage()">
              <b-row>
                <b-col md="4">
                  <b-form-group
                    label="Actual Drive Time"
                    label-for="actualDriveTimeTo"
                    class="nowrap-label-title"
                  >
                    <b-form-input
                      id="actualDriveTimeTo"
                      :value="parseMinutesToHours(ActualDriveTimeTo)"
                      name="actualDriveTimeTo"
                      :readonly="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Est Mileage"
                    label-for="estimatedMileage"
                    class="nowrap-label-title"
                  >
                    <b-form-input
                      id="estimatedMileage"
                      :value="ActivityTravel.to && ActivityTravel.to.estimatedDistance || '- - -'"
                      name="estimatedMileage"
                      :readonly="true"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group
                    label="Actual Mileage if different"
                    label-for="actualMileage"
                    class="nowrap-label-title"
                  >
                    <b-form-input
                      id="actualMileage"
                      v-model="actualToDistanceMiles"
                      @keydown="preventNegativeSign"
                      :formatter="(val) => formatInput(val, 'actualToDistanceMiles')"
                      placeholder=""
                      type="number"
                      min="0"
                      name="actualMileage"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group
                    v-if="actualToDistanceMiles"
                    label="Reason"
                    label-for="collapse-textarea"
                  >
                    <div
                      class="collapse-textarea"
                      :class="{expanded: !isExpandedTimeReason}"
                    >
                      <textarea
                        v-model="actualToReason"
                        class="expandable-textarea"
                        :class="[isExpandedTimeReason ? 'not-expanded' : 'expanded']"
                        contentEditable
                        :readonly="readOnly"
                      />
                      <div @click="isExpandedTimeReason = !isExpandedTimeReason">
                        <span
                          class="expand-arrow mt-n1"
                          :class="[isExpandedTimeReason ? 'not-expanded' : 'expanded']"
                        >&#187;</span>
                      </div>
                    </div>
                  </b-form-group>
                  <div v-if="!actualToDistanceMiles" class="empty-block"/>
                </b-col>
              </b-row>

              <b-row v-if="getCurrentScopes.indexOf('st2.viewcalculatedtravelactivity') !== -1">
                <b-col md="4">
                  <b-form-group
                    label="Est Drive Time"
                    label-for="driveTime"
                    class="nowrap-label-title"
                  >
                    <b-form-input
                      id="driveTime"
                      :value="parseMinutesToHours(ActivityTravel.to && ActivityTravel.to.timePerDistance)"
                      name="driveTime"
                      :readonly="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Mapped Drive Time"
                    label-for="estimatedMileage"
                    class="nowrap-label-title"
                  >
                    <b-form-input
                      id="estimatedMileage"
                      :value="parseMinutesToHours(ActivityTravel.to && ActivityTravel.to.estimatedTimeMinutes)"
                      name="estimatedMileage"
                      :readonly="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="for-border-box">
            <b-col>
              <b-form-group
                label="On Site Departure Time"
                label-for="onSiteDepartureDate"
              >
                <b-row>
                  <b-col>
                    <b-form-input
                      id="onSiteDepartureDate"
                      v-model="OnSiteDepartureDate"
                      type="date"
                      placeholder=""
                      name="onSiteDepartureDate"
                      :readonly="readOnly"
                    />
                  </b-col>
                  <b-col>
                    <b-form-input
                      id="onSiteDepartureTime"
                      v-model="OnSiteDepartureTime"
                      type="time"
                      placeholder=""
                      name="onSiteDepartureTime"
                      :readonly="readOnly"
                    />
                  </b-col>
                </b-row>
              </b-form-group>

              <b-form-group
                label="Activity End Time"
                label-for="activityEndDate"
              >
                <b-row>
                  <b-col>
                    <b-form-input
                      id="activityEndDate"
                      v-model="ActivityEndDate"
                      type="date"
                      placeholder=""
                      name="activityEndDate"
                      :readonly="readOnly"
                    />
                  </b-col>
                  <b-col>
                    <b-form-input
                      id="activityEndTime"
                      v-model="ActivityEndTime"
                      type="time"
                      placeholder=""
                      name="activityEndTime"
                      :readonly="readOnly"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="showEstimatedMileage()">
              <b-row>
                <b-col md="4">
                  <b-form-group
                    label="Actual Drive Time"
                    label-for="actualDriveTimeFrom"
                    class="nowrap-label-title"
                  >
                    <b-form-input
                      id="actualDriveTimeFrom"
                      :value="parseMinutesToHours(ActualDriveTimeFrom)"
                      name="actualDriveTimeFrom"
                      :readonly="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Est Mileage"
                    label-for="estimatedMileageFrom"
                    class="nowrap-label-title"
                  >
                    <b-form-input
                      id="estimatedMileageFrom"
                      :value="ActivityTravel.from && ActivityTravel.from.estimatedDistance || '- - -'"
                      name="estimatedMileageFrom"
                      :readonly="true"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group
                    label="Actual Mileage if different"
                    label-for="actualMileageFrom"
                    class="nowrap-label-title"
                  >
                    <b-form-input
                      id="actualMileageFrom"
                      v-model="actualFromDistanceMiles"
                      @keydown="preventNegativeSign"
                      :formatter="(val) => formatInput(val, 'actualFromDistanceMiles')"
                      placeholder=""
                      type="number"
                      min="0"
                      name="actualMileageFrom"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group
                    v-if="actualFromDistanceMiles"
                    label="Reason"
                    label-for="collapse-textarea"
                  >
                    <div
                      class="collapse-textarea"
                      :class="{expanded: !isExpandedTimeReasonFrom}"
                    >
                      <textarea
                        v-model="actualFromReason"
                        class="expandable-textarea"
                        :class="[isExpandedTimeReasonFrom ? 'not-expanded' : 'expanded']"
                        contentEditable
                        :readonly="readOnly"
                      />
                      <div @click="isExpandedTimeReasonFrom = !isExpandedTimeReasonFrom">
                        <span
                          class="expand-arrow mt-n1"
                          :class="[isExpandedTimeReasonFrom ? 'not-expanded' : 'expanded']"
                        >&#187;</span>
                      </div>
                    </div>
                  </b-form-group>
                  <div v-if="!actualFromDistanceMiles" class="empty-block"/>
                </b-col>
              </b-row>
              <b-row v-if="getCurrentScopes.indexOf('st2.viewcalculatedtravelactivity') !== -1">
                <b-col md="4">
                  <b-form-group
                    label="Est Drive Time"
                    label-for="driveTimeFrom"
                    class="nowrap-label-title"
                  >
                    <b-form-input
                      id="driveTimeFrom"
                      :value="parseMinutesToHours(ActivityTravel.from && ActivityTravel.from.timePerDistance)"
                      name="driveTimeFrom"
                      :readonly="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Mapped Drive Time"
                    label-for="estimatedMileage"
                    class="nowrap-label-title"
                  >
                    <b-form-input
                      id="estimatedMileage"
                      :value="parseMinutesToHours(ActivityTravel.to && ActivityTravel.from.estimatedTimeMinutes)"
                      name="estimatedMileage"
                      :readonly="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="4" v-if="!showEstimatedMileage()"></b-col>
        <b-col md="3">
          <b-row>
            <b-col>
              <b-form-group
                label="Vehicle"
                label-for="vehicles"
                class="required-data"
              >
                <v-select
                  id="vehicle"
                  v-model="Vehicle"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="vehicleOptions"
                  placeholder=""
                  name="vehicle"
                  :disabled="readOnly"
                />
                <small
                  v-if="!Vehicle || Vehicle.value <= 0"
                  class="text-danger"
                >
                  Vehicle is required
                </small>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="Vehicle && Vehicle.title.indexOf('Personally Owned Vehicle') !== -1">
            <b-col>
              <b-form-group
                label="POV"
                label-for="pov"
              >
                <v-select
                  id="pov"
                  v-model="POV"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="povOptions"
                  name="pov"
                  placeholder="Sorry, you have no car"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="Mileage || getCurrentScopes.indexOf('st2.betafeatures') === -1">
            <b-col>
              <b-form-group
                label="Mileage"
                label-for="mileage"
              >
                <b-form-input
                  id="mileage"
                  v-model="Mileage"
                  type="number"
                  min="0"
                  placeholder=""
                  name="mileage"
                  :readonly="getCurrentScopes.indexOf('st2.betafeatures') !== -1"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="pl-2">
          <b-row class="pl-1 pb-1">
            <b-col md="0.5">
              <strong>Yes</strong>
            </b-col>
            <b-col>
              <strong>No</strong>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="3">
              <b-form-radio-group
                v-model="HotelStay"
                :options="options"
              />
            </b-col>
            <b-col>
              <span>Hotel Stay</span>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="3">
              <b-form-radio-group
                v-model="IntegrityOnly"
                :options="options"
              />
            </b-col>
            <b-col>
              <span>Integrity Only</span>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="3">
              <b-form-radio-group
                v-model="ClaimantVideoObtained"
                :options="options"
              />
            </b-col>
            <b-col>
              <span>Claimant Video Obtained</span>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="3">
              <b-form-radio-group
                v-model="AudioObtained"
                :options="options"
              />
            </b-col>
            <b-col>
              <span>Audio Obtained</span>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="3">
              <b-form-radio-group
                v-model="CompromiseByTarget"
                :options="options"
              />
            </b-col>
            <b-col>
              <span>Compromise by Target</span>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="3">
              <b-form-radio-group
                v-model="MobileSurveillanceConducted"
                :options="options"
              />
            </b-col>
            <b-col>
              <span>Mobile Surveillance Conducted</span>
            </b-col>
          </b-row>

          <b-row v-if="MobileSurveillanceConducted">
            <b-col md="3">
              <b-form-radio-group
                v-model="MobileSurveillanceLost"
                :options="options"
              />
            </b-col>
            <b-col>
              <span>Mobile Surveillance Lost</span>
            </b-col>
          </b-row>

          <b-row v-if="MobileSurveillanceLost">
            <b-col md="3">
              <b-form-radio-group
                v-model="MobileSurveillanceRecovery"
                :options="options"
              />
            </b-col>
            <b-col>
              <span>Mobile Surveillance Recovery</span>
            </b-col>
          </b-row>
        </b-col>

        <b-col>
          <b-row
            v-if="ClaimantVideoObtained"
            class="pt-2"
          >
            <b-col>
              <div class="text-right">
                Video Vehicle/Camcorder, Video Mins Obtained
              </div>
            </b-col>
            <b-col>
              <b-form-input
                id="videoVehicle"
                v-model="VideoVehicle"
                type="number"
                min="0"
                placeholder=""
                name="videoVehicle"
                :readonly="readOnly"
              />
            </b-col>
          </b-row>
          <b-row
            v-if="ClaimantVideoObtained"
            class="pt-1"
          >
            <b-col>
              <div class="text-right">
                Video Covert Mins Obtained
              </div>
            </b-col>
            <b-col>
              <b-form-input
                id="videoCovertMinsObtained"
                v-model="VideoCovertMinsObtained"
                type="number"
                min="0"
                placeholder=""
                name="videoCovertMinsObtained"
                :readonly="readOnly"
              />
            </b-col>
          </b-row>
          <b-row class="pt-1">
            <b-col>
              <div class="text-right">
                Total Video Clip Count
              </div>
            </b-col>
            <b-col>
              <b-form-input
                id="totalVideoClipCount"
                v-model="TotalVideoClipCount"
                type="number"
                min="0"
                placeholder=""
                name="totalVideoClipCount"
                :readonly="readOnly"
              />
            </b-col>
          </b-row>
          <b-row class="pt-1">
            <b-col>
              <div class="text-right">
                Rating
              </div>
            </b-col>
            <b-col>
              <v-select
                id="rating"
                v-model="Rating"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="ratingOptions"
                placeholder=""
                name="rating"
                :disabled="readOnly"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6" class="pl-2">
          <b-row>
            <b-col>
              <b-row>
                <b-col md="3">
                  <b-form-radio-group
                    v-model="IsCaseWritingWork15Minutes"
                    :options="options"
                  />
                </b-col>
                <b-col>
                  <span>15 minutes for Case Writing work</span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="pl-2">
          <b-row v-if="IsCaseWritingWork15Minutes">
            <b-col md="6">
              <b-row>
                <b-col md="3">
                  <b-form-radio-group
                    v-model="IsAdditionalTimeOver15Minutes"
                    :options="options"
                  />
                </b-col>
                <b-col>
                  <span>Additional time over 15 minutes needed</span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="IsAdditionalTimeOver15Minutes && IsCaseWritingWork15Minutes">
            <b-col>
              <b-row>
                <b-col md="3"></b-col>
                <b-col>
                  <b-row>
                    <b-col md="3">
                      <b-form-input
                        id="minutesOver15"
                        v-model="MinutesOver15"
                        @keydown="preventNegativeSign"
                        :formatter="(val) => formatInput(val, 'MinutesOver15')"
                        type="number"
                        min="0"
                        name="minutesOver15"
                      />
                    </b-col>
                    <b-col>
                      <div class="text-nowrap">
                        Minutes over 15
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>

            <b-col>
              <b-row v-if="MinutesOver15 && IsAdditionalTimeOver15Minutes && IsCaseWritingWork15Minutes">
                <b-col md="3">
                  <div class="text-right">
                    Reason:
                  </div>
                </b-col>
                <b-col>
                  <div
                    class="collapse-textarea"
                    :class="{expanded: !isExpandedMinutesOver15AppearsReason}"
                  >
                    <textarea
                      v-model="MinutesOver15Reason"
                      class="expandable-textarea"
                      :class="[isExpandedMinutesOver15AppearsReason ? 'not-expanded' : 'expanded']"
                      contentEditable
                    />
                    <div @click="isExpandedMinutesOver15AppearsReason = !isExpandedMinutesOver15AppearsReason">
                      <span
                        class="expand-arrow mt-n1"
                        :class="[isExpandedMinutesOver15AppearsReason ? 'not-expanded' : 'expanded']"
                      >&#187;</span>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row
        v-if="MobileSurveillanceRecovery"
        class="pt-1"
      >
        <b-col md="3">
          <div class="text-right">
            Recovery Explanation:
          </div>
        </b-col>
        <b-col md="8">
          <div
            class="collapse-textarea"
            :class="{expanded: !isExpanded}"
          >
            <textarea
              v-model="RecoveryExplanation"
              class="expandable-textarea"
              :class="[isExpanded ? 'not-expanded' : 'expanded']"
              contentEditable
              :readonly="readOnly"
            />
            <div @click="isExpanded = !isExpanded">
              <span
                class="expand-arrow mt-n1"
                :class="[isExpanded ? 'not-expanded' : 'expanded']"
              >&#187;</span>
            </div>
          </div>
        </b-col>
      </b-row>

    </div>
    <b-row class="pt-2">
      <b-col>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="mr-2"
          type="reset"
          variant="danger"
          @click="resetForm()"
          :disabled="isLoading"
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="submit"
          variant="primary"
          @click="saveForm()"
          :disabled="isLoading"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      id="modal-confirm-save"
      ok-variant="danger"
      ok-title="OK"
      cancel-title="Cancel"
      modal-class="modal-warning"
      hide-header-close
      centered
      @ok="confirmSave()"
    >
      <div>
        This activity is greater than 24 hours.  Please confirm this is intended.
      </div>
    </b-modal>
  </div>
</template>

<script>
import {BButton, BCol, BFormGroup, BFormInput, BFormRadioGroup, BRow} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import APIService from "@core/utils/APIService";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {mapGetters} from "vuex";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      ActivityID: "",
      actionObj: {},

      ActionID: "",

      ActivityBeginDate: "",
      ActivityBeginTime: "",
      OnSiteArrivalDate: "",
      OnSiteArrivalTime: "",
      OnSiteDepartureDate: "",
      OnSiteDepartureTime: "",
      ActivityEndDate: "",
      ActivityEndTime: "",
      Rating: null,

      Vehicle: {title: "", value: 0},
      POV: null,
      Mileage: null,

      vehicleOptions: [],
      povOptions: [],

      HotelStay: null,
      IntegrityOnly: null,
      ClaimantVideoObtained: null,
      AudioObtained: null,
      CompromiseByTarget: null,
      MobileSurveillanceConducted: null,
      MobileSurveillanceLost: null,
      MobileSurveillanceRecovery: null,
      ClaimantFirstObserved: false,

      IsAdditionalTimeOver15Minutes: null,
      IsCaseWritingWork15Minutes: null,
      MinutesOver15: "",
      MinutesOver15Reason: "",
      isExpandedMinutesOver15AppearsReason: false,

      options: [
        { text: '', value: true },
        { text: '', value: false },
      ],

      ratingOptions: [1, 2, 3, 4],
      VideoVehicle: 0,
      VideoCovertMinsObtained: 0,
      TotalVideoClipCount: 0,

      RecoveryExplanation: "",
      isExpanded: false,

      readOnly: false,
      isLoading: false,

      sendForApprove: 0,
      FlagForApproval: false,

      ActivityTravel: {
        from: {
          estimatedDistance: null,
          estimatedTimeMinutes: null,
          timePerDistance: null,
        },
        to: {
          estimatedDistance: null,
          estimatedTimeMinutes: null,
          timePerDistance: null,
        },
      },
      actualFromDistanceMiles: "",
      actualFromReason: "",
      actualToDistanceMiles: "",
      actualToReason: "",
      ActualDriveTimeTo: "",
      ActualDriveTimeFrom: "",

      isExpandedTimeReason: false,
      isExpandedTimeReasonFrom: false,
    }
  },
  computed: {
    ...mapGetters({
      getCurrentScopes: "scopes/getCurrentScopes",
    }),
  },
  watch: {
    actionObj: {
      handler(val) {
        for (let key in val) {
          if (key === "IsTravelRequired" && val[key] && !this.ActivityID) {
            this.Vehicle =  this.vehicleOptions.find(i => i.unitNumber === "POV");
          }
          if (key === "Assignments" && val[key]) {
            if (val[key] && val.IsTravelRequired) {
              if (!this.ActivityID) this.getEstimatedMileage();
              if (!this.ActivityID && !this.checkAssignments()) {
                this.showToast('danger', 'top-center', 4000, JSON.stringify('Your user is not assigned to this action!'))
              }
            }
          }
        }
      },
      deep: true
    },
    Vehicle(val) {
      if (val && val.title.indexOf('Personally Owned Vehicle') !== -1) {
        this.POV = this.povOptions[0] || null;
      }
    },
    IntegrityOnly(val) {
      if (val) {
        this.ClaimantVideoObtained = false;
        this.AudioObtained = false;
      }
    },
    AudioObtained(val) {
      if (val) {
        this.ClaimantVideoObtained = false;
        this.IntegrityOnly = false;
      }
    },
    ClaimantVideoObtained(val) {
      if (val) {
        this.AudioObtained = false;
        this.IntegrityOnly = false;
      }
    },
    MobileSurveillanceConducted(val) {
      if (!val) {
        this.MobileSurveillanceLost = null;
        this.MobileSurveillanceRecovery = null;
      }
    },
    MobileSurveillanceLost(val) {
      if (!val) this.MobileSurveillanceRecovery = null;
    },
    MobileSurveillanceRecovery(val) {
      if (!val) {
        this.RecoveryExplanation = "";
      }
    },

    IsCaseWritingWork15Minutes(val) {
      if (!val) {
        this.IsAdditionalTimeOver15Minutes = null;
        this.MinutesOver15 = null;
        this.MinutesOver15Reason = null;
      }
    },
    IsAdditionalTimeOver15Minutes(val) {
      if (!val) {
        this.MinutesOver15 = null;
        this.MinutesOver15Reason = null;
      }
    },
    MinutesOver15(val) {
      if (!val) {
        this.MinutesOver15Reason = null;
      }
    },

    ActivityBeginDate() {
      this.isLoading = false;
      this.checkActivityTime()
    },
    ActivityBeginTime() {
      this.isLoading = false;
      this.checkActivityTime()
    },
    OnSiteArrivalDate() {
      this.isLoading = false;
      this.checkActivityTime()
    },
    OnSiteArrivalTime() {
      this.isLoading = false;
      this.checkActivityTime()
    },
    OnSiteDepartureDate() {
      this.isLoading = false;
      this.checkActivityTime()
    },
    OnSiteDepartureTime() {
      this.isLoading = false;
      this.checkActivityTime()
    },
    ActivityEndDate() {
      this.isLoading = false;
      this.checkActivityTime()
    },
    ActivityEndTime() {
      this.isLoading = false;
      this.checkActivityTime()
    },
  },
  created() {
    this.ActionID = this.$route.params.actionID;
    this.ActivityID = this.$route.params.activityID;
    this.getVehicles();
    if (this.ActionID) this.getAction();
    if (this.ActivityID) {
      this.getActivity();
    } else {
      const uid = localStorage.getItem('UID')
      this.getPOV(uid);
    }
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },

    getAction() {
      apiService
          .get("action/" + this.ActionID + "/detail")
          .then(res => {
            this.actionObj = res.data;
            if ( res.data.ScheduledDate)
              this.ActivityBeginDate = this.OnSiteArrivalDate = this.OnSiteDepartureDate = this.ActivityEndDate = res.data.ScheduledDate.split('T')[0]
            this.ActivityBeginTime = this.OnSiteArrivalTime = res.data.TimeWindowStart
            this.OnSiteDepartureTime = this.ActivityEndTime = res.data.TimeWindowEnd
          })
    },

    getActivity() {
      apiService
          .get("activity/" + this.ActivityID + "/detail")
          .then(res => {
            this.ActionID = res.data.ActionID
            this.ActivityBeginTime = this.timeFormat(res.data.BeginTime)
            this.OnSiteArrivalTime = this.timeFormat(res.data.OnSiteArrivalTime);
            this.OnSiteDepartureTime = this.timeFormat(res.data.OnSiteDepartureTime);
            this.ActivityEndTime = this.timeFormat(res.data.EndTime);
            this.ActivityBeginDate = res.data.BeginTime ? res.data.BeginTime.split('T')[0] : null;
            this.OnSiteArrivalDate = res.data.OnSiteArrivalTime ? res.data.OnSiteArrivalTime.split('T')[0] : null;
            this.OnSiteDepartureDate = res.data.OnSiteDepartureTime ? res.data.OnSiteDepartureTime.split('T')[0] : null;
            this.ActivityEndDate = res.data.EndTime ? res.data.EndTime.split('T')[0] : null;
            this.Mileage = res.data.Mileage;
            this.HotelStay = res.data.HotelStay;
            this.IntegrityOnly = res.data.IntegrityOnly;
            this.ClaimantVideoObtained = res.data.VideoObtained;
            this.AudioObtained = res.data.AudioObtained;
            this.CompromiseByTarget = res.data.CompromiseByTarget;
            this.MobileSurveillanceConducted = res.data.MobileSurveillanceConducted;
            this.MobileSurveillanceLost = res.data.MobileSurveillanceLost;
            this.MobileSurveillanceRecovery = res.data.MobileSurveillanceRecovery;
            this.ClaimantFirstObserved = res.data.ClaimantFirstObserved;
            this.RecoveryExplanation = res.data.MobileSurveillanceRecoveryExplanation;
            this.VideoVehicle = res.data.VideoObtainedMinsVehicle;
            this.VideoCovertMinsObtained = res.data.VideoObtainedMinsCovert;
            this.TotalVideoClipCount = res.data.VideoTotalClipCount;
            this.Rating = res.data.Rating;
            this.IsAdditionalTimeOver15Minutes = res.data.IsAdditionalTimeOver15Minutes;
            this.IsCaseWritingWork15Minutes = res.data.IsCaseWritingWork15Minutes;
            this.MinutesOver15 = res.data.MinutesOver15 || "";
            this.MinutesOver15Reason = res.data.MinutesOver15Reason;
            this.FlagForApproval = res.data.FlagForApproval;
            this.ActivityTravel = this.transformKeysToLowerCase(res.data.ActivityTravel)
            this.actualFromDistanceMiles = res.data.ActivityTravel.From.ActualDistance || "";
            this.actualFromReason = res.data.ActivityTravel.From.ActualReason;
            this.actualToDistanceMiles = res.data.ActivityTravel.To.ActualDistance || "";
            this.actualToReason = res.data.ActivityTravel.To.ActualReason;

            this.getPOV(res.data.UserID, res.data.UserOwnVehicleID);
            if (res.data.VehicleID) this.getVehicles(res.data.VehicleID);
            apiService
                .get("action/" + this.ActionID + "/detail")
                .then(response => {
                  this.actionObj = response.data;
                })
          })
    },

    resetForm() {
      if (this.ActivityID) {
        this.$router.push('/cases/view-activity/' + this.ActivityID);
      } else {
        this.$router.push('/cases/edit-action/' + this.ActionID);
      }
    },

    confirmSave() {
      this.sendForApprove = 0;
      this.saveForm();
    },

    saveForm() {
      if (this.Vehicle.value === 0) {
        this.showToast('danger', 'top-center', 4000, JSON.stringify('Could not save. Vehicle is required.'))
        return;
      }
      if (!this.ActivityBeginTime || !this.OnSiteArrivalTime || !this.OnSiteDepartureTime || !this.ActivityEndTime)
       {
        this.showToast('danger', 'top-center', 4000, JSON.stringify('Must enter valid times.'))
        return;
      }
      if (this.IsAdditionalTimeOver15Minutes && (!this.MinutesOver15 || !this.MinutesOver15Reason)) {
        this.showToast('danger', 'top-center', 4000, JSON.stringify('You must enter at least 1 minute and reason. Otherwise, choose No for over additional time.'))
        return;
      }
      if ((this.actualFromDistanceMiles && !this.actualFromReason) || (this.actualToDistanceMiles && !this.actualToReason)) {
        this.showToast('danger', 'top-center', 4000, JSON.stringify('When setting the actual distance, you must specify the reason.'))
        return;
      }
      if (this.sendForApprove === 2) {
        this.$bvModal.show("modal-confirm-save")
        return;
      }

      this.isLoading = true;
      let postData = {
        "ActivityType": this.actionObj.ActionTypeName,
        "CaseID": this.actionObj.CaseID,
        "ActionID": this.ActionID,
        "SubjectID": this.actionObj.SubjectID,
        "BeginTime": this.locationDeviation(this.ActivityBeginDate, this.ActivityBeginTime),
        "OnSiteArrivalTime": this.locationDeviation(this.OnSiteArrivalDate, this.OnSiteArrivalTime),
        "OnSiteDepartureTime": this.locationDeviation(this.OnSiteDepartureDate, this.OnSiteDepartureTime),
        "EndTime": this.locationDeviation(this.ActivityEndDate, this.ActivityEndTime),
        "VehicleID": this.Vehicle.value === 0 ? null : this.Vehicle.value,
        "Mileage": this.Mileage,
        "HotelStay": this.HotelStay,
        "IntegrityOnly": this.IntegrityOnly,
        "VideoObtained": this.ClaimantVideoObtained,
        "AudioObtained": this.AudioObtained,
        "CompromiseByTarget": this.CompromiseByTarget,
        "MobileSurveillanceConducted": this.MobileSurveillanceConducted,
        "MobileSurveillanceLost": this.MobileSurveillanceLost,
        "MobileSurveillanceRecovery": this.MobileSurveillanceRecovery,
        "ClaimantFirstObserved": this.ClaimantFirstObserved,
        "MobileSurveillanceRecoveryExplanation": this.RecoveryExplanation,
        "VideoObtainedMinsCovert": this.VideoCovertMinsObtained,
        "VideoObtainedMinsVehicle": this.VideoVehicle,
        "VideoTotalClipCount": this.TotalVideoClipCount,
        "Rating": this.Rating,
        "UserOwnVehicleID": this.Vehicle.title.indexOf('Personally Owned Vehicle') !== -1 ? this.POV ? this.POV.value : null : null,
        "ActualFromDistanceMiles":  this.actualFromDistanceMiles ? Number(this.actualFromDistanceMiles) : null,
        "ActualFromReason": this.actualFromReason,
        "ActualToDistanceMiles": this.actualToDistanceMiles ? Number(this.actualToDistanceMiles) : null,
        "ActualToReason": this.actualToReason,
        "IsAdditionalTimeOver15Minutes": this.IsAdditionalTimeOver15Minutes,
        "IsCaseWritingWork15Minutes": this.IsCaseWritingWork15Minutes,
        "MinutesOver15": this.MinutesOver15,
        "MinutesOver15Reason": this.MinutesOver15Reason
      };

      const endpoint = this.ActivityID ? "activity/" + this.ActivityID + "/edit" : "activity/create";
      apiService
          .post(endpoint, postData)
          .then(res => {
            if (res) {
              if (this.sendForApprove === 1) this.showToast("success", "top-center", 4000, "The activity duration was more than 12 hours.  Please ensure the times are correct.");
              this.sendForApprove = 0
              this.showToast("success", "top-center", 4000, this.ActivityID ? "Activity edited" : "Activity created");
              const ActivityID = this.ActivityID ? this.ActivityID : res.data.activityID
              this.$router.push('/cases/view-activity/' + ActivityID);
            }
            this.isLoading = false;
          })
    },

    getVehicles(VehicleID = null) {
      apiService.get("vehicles/list")
          .then(res => {
            this.vehicleOptions = res.data.map(i => ({
              title: `${i.UnitNumber} - ${i.Name}`,
              value: i.VehicleID,
              unitNumber: i.UnitNumber
            }));
            this.Vehicle = VehicleID ?
                this.vehicleOptions.find(i => i.value === VehicleID) :
                this.vehicleOptions.find(i => i.unitNumber === "N/A");
          });
    },

    getPOV(userId, VehicleID = null) {
      apiService.get("user/" + userId + "/vehicles")
          .then(res => {
            if (res.data && res.data.length) {
              const userVehicles = res.data.filter(i => !i.isDeleted)
              this.povOptions = userVehicles.map(i => ({
                title: (i.year ? i.year : "") + " " + (i.manufacture ? i.manufacture : "") + " " + (i.model ? i.model : ""),
                value: i.id,
                unitNumber: i.id
              }));
              if (VehicleID !== null) {
                this.POV = this.povOptions.filter(i => i.value === VehicleID);
              } else {
                this.POV = this.povOptions[0]
              }
            }
          })
    },

    timeFormat(val) {
      val = new Date(val).toTimeString().split(' ')[0];
      return val
    },

    locationDeviation(date, time) {
      const totalDate = new Date(date + "T" + time);
      return new Date(totalDate - (totalDate.getTimezoneOffset() * 60000))
    },

    checkActivityTime() {
      setTimeout(() => {
        const ActivityBegin = this.locationDeviation(this.ActivityBeginDate, this.ActivityBeginTime)
        const OnSiteArrival = this.locationDeviation(this.OnSiteArrivalDate, this.OnSiteArrivalTime)
        const OnSiteDeparture = this.locationDeviation(this.OnSiteDepartureDate, this.OnSiteDepartureTime)
        const ActivityEnd = this.locationDeviation(this.ActivityEndDate, this.ActivityEndTime)

        if (ActivityBegin.getTime() > OnSiteArrival.getTime() || OnSiteArrival.getTime() > OnSiteDeparture.getTime() || OnSiteDeparture.getTime() > ActivityEnd.getTime()) {
          if (!this.isLoading) {
            this.isLoading = true;
            this.showToast('danger', 'top-center', 4000, JSON.stringify('The activity times are not ascending in time.'))
          }
        } else {
          if (!this.isLoading) {
            if (Math.round((ActivityEnd.getTime() - ActivityBegin.getTime()) / (1000 * 60)) > 60 * 12) this.sendForApprove = 1;
            if (Math.round((ActivityEnd.getTime() - ActivityBegin.getTime()) / (1000 * 60)) > 60 * 24) this.sendForApprove = 2;
            this.getActualDriveTime(ActivityBegin, OnSiteArrival, OnSiteDeparture, ActivityEnd);
          }
        }
      }, 1500)
    },

    getActualDriveTime(ActivityBegin, OnSiteArrival, OnSiteDeparture, ActivityEnd) {
      this.ActualDriveTimeTo = Math.round((OnSiteArrival.getTime() - ActivityBegin.getTime()) / (1000 * 60));
      this.ActualDriveTimeFrom = Math.round((ActivityEnd.getTime() - OnSiteDeparture.getTime()) / (1000 * 60));
    },

    showEstimatedMileage() {
      return !!(this.actionObj.IsTravelRequired && this.Vehicle && this.Vehicle.title !== "N/A - Not Applicable" && this.getCurrentScopes.indexOf('st2.betafeatures') !== -1);
    },

    getEstimatedMileage() {
      apiService.get("distances/user-action?actionId=" + this.ActionID)
          .then(res => {
            this.ActivityTravel = res.data;
          })
    },

    parseMinutesToHours(minutes) {
      if (!minutes) return "- h - - min"
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours} h ${remainingMinutes} min`;
    },

    transformKeysToLowerCase(obj) {
      if (typeof obj !== 'object' || obj === null) {
        return obj;
      }

      return Object.keys(obj).reduce((newObj, key) => {
        const newKey = key.charAt(0).toLowerCase() + key.slice(1);
        newObj[newKey] = this.transformKeysToLowerCase(obj[key]);
        return newObj;
      }, {});
    },

    preventNegativeSign(event) {
      if (event.key === '.') {
        const message = event.target.id.indexOf('minutesOver15') !== -1
            ? "Time should not include decimals."
            : "Mileage should not include decimals.";

        this.showToast('danger', 'top-center', 4000, JSON.stringify(message));
      }
      if (event.key === '-' || event.key === '+' || event.key === '.') {
        event.preventDefault();
      }
    },

    formatInput(value, target) {
      if (this[target] === value) return value
      if (!value || value === "0") {
        const message = target === "MinutesOver15"
            ? "You must enter at least 1 minute. Otherwise, choose 'No' for over additional time."
            : "Mileage must be greater or equal to 1 mile.";

        this.showToast('danger', 'top-center', 4000, JSON.stringify(message));
        return '';
      }
      return value.replace(/\D/g, '');
    },

    checkAssignments() {
      const UID = localStorage.getItem('UID');
      let isChecked = false;
      if (UID && this.actionObj && this.actionObj.Assignments && this.actionObj.Assignments.length) {
        this.actionObj.Assignments.map(item => {
          if (Number(item.UserAccountID) === Number(UID)) {
            isChecked = true;
          }
        })
      }
      return isChecked;
    },
  },
}
</script>

<style scoped>
.empty-block {
  width: 2rem;
  height: 6rem;
}
.nowrap-label-title {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%
}
.for-border-box,
.to-border-box {
  border: 1px solid #d8d6de;
  padding-top: 1rem;
  margin: 0.3rem;
  box-shadow: 0 8px 25px -8px gray;
  padding-bottom: 1rem;
}
.for-border-box {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>